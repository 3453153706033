import React from 'react';
import LinkedInIcon from './0000.JPG'; // Adjust the path if your image is in a different folder

function App() {
  return (
    <div style={{ textAlign: 'center', marginTop: '50px' }}>
      <h1>Welcome to My Simple Frontend App!</h1>

      <p>This is a simple React application deployed using Docker and AWS.</p>
      <p>by me Abdulrahman Ibrahim Mohammed Alnwisser</p>
      
      <a 
        href="https://www.linkedin.com/in/abdulrahman-alnwisser1" 
        target="_blank" 
        rel="noopener noreferrer"
        style={{ display: 'inline-flex', alignItems: 'center', textDecoration: 'none', color: 'inherit' }}
      >
        LinkedIn
        <img
          src={LinkedInIcon}
          alt="LinkedIn"
          style={{ width: '401px', height: '601px', marginLeft: '8px' }} // Adjust size and spacing as needed
        />
      </a>
      
      <p>A new DevOps Engineer</p>
    </div>
  );
}

export default App;
